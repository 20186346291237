import React from "react";
import '../styles/Home.css';
import '../fonts/fonts.css';
import playstore from '../assets/img/playstore.png';
function Home() {
    return (
        <section id="home" className="hero">
            <div id="hero-img"> </div>
            <div className="home-content">
                <h1>Welcome to <span id="greenMedaresha">Medaresha</span></h1>
                <p>Order / Book (Room, Food, Drink)</p>
                {/* <a href="#" className="button">Download The App On PlayStore</a> */}
                <a href="#"><img src={playstore} alt="Car Image" className="playstore" /></a>
            </div>
        </section>
    ) 
}   

export default Home;