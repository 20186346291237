import React from "react";
import '../styles/Contact.css';
import '../fonts/fonts.css';

function ContactUs() {
    return (
        <section id="contact-us">
  <div class="contact-content">
    <h2>Contact Us</h2>
    <div class="contact-details">
      <div class="contact-item">
        <span class="contact-label">Call:</span>
        <span class="contact-info">+251 92 632 9275</span>
      </div>
      <div class="contact-item">
        <span class="contact-label">Email:</span>
        <span class="contact-info">mishdesta@gmail.com</span>
      </div>
      <div class="contact-item">
        <span class="contact-label">Location:</span>
        <span class="contact-info">Ethiopia (Addis Ababa)</span>
      </div>
    </div>
    <div class="contact-form">
      <h3>Send us a message</h3>
      <form id="contact-form">
        <div class="form-group">
          <input type="text" id="name" name="name" placeholder="Your Name" required  />
        </div>
        <div class="form-group">
          <input type="email" id="email" name="email" placeholder="Your Email" required />
        </div>
        <div class="form-group">
          <input type="text" id="subject" name="subject" placeholder="Subject" required />
        </div>
        <div class="form-group">
          <textarea id="message" name="message" rows="4" placeholder="Your Message" required ></textarea>
        </div>
        <button type="submit" class="submit-button">Submit</button>
      </form>
    </div>
  </div>
</section>
    )
}

export default ContactUs;