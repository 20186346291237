import React, { useState, useEffect } from "react";
import HeaderLogo from "../assets/img/medaresha logo.jpg";
import "../styles/Header.css";

function Header() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header id="header">
      {isMobile ? (
        <a href="#home" className="logo">
          Medaresha
        </a>
      ) : (
        <>
          <a href="#home" className="logo">
            Medaresha
          </a>
          <div id="nav-links">
            <a href="#home">Home</a>
            <a href="#about-us">About</a>
            <a href="#why-us">Why Us</a>
            <a href="#contact-us">Contact Us</a>
          </div>
        </>
      )}
    </header>
  );
}

export default Header;


























// import React from "react";
// import HeaderLogo from "../assets/img/medaresha logo.jpg";
// import '../styles/Header.css';
// import '../fonts/fonts.css';

// function Header() {
//     return (
//         <header id="header">
//             <a class="logo" href="#home">Medaresha</a>
//             <div id="nav-links" class="hidden">
//                 <a href="#home">Home</a>
//                 <a href="#about-us">About</a>
//                 <a href="#why-us">WhyUs</a>
//             </div>
//             <div className="contact-us">
//                 <span><a>Contact Us</a></span>
//             </div>
//         </header>
//     )
// }

// export default Header;