import React from 'react';
import '../styles/WhyUs.css';
import '../fonts/fonts.css';

function WhyUs() {
    return (
        <section id="why-us">
            <h2>What do we do?</h2>
            <div class="reasons">
                <div class="reason">
                    <div class="icon">01</div>
                    <h3>Product</h3>
                    <p>The product will be a web-based mobile app that offers users the ability to browse services within an area and select the necessary category of the business they wish to find and book.</p>
                </div>
                <div class="reason">
                    <div class="icon">02</div>
                    <h3>Help & Support</h3>
                    <p>We facilitate trips and help users to navigate, share, rate, and review functionality that links to other service providers.</p>
                </div>
                <div class="reason">
                    <div class="icon">03</div>
                    <h3>Service Providers</h3>
                    <p>On the service providers' side, we will help service providers locate and promote their services. The service provider will also manage user feedback about their services to have smooth communication with customers regarding their service.</p>
                </div>
            </div>
        </section>
    );
}

export default WhyUs;


