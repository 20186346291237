import React from 'react';
import './styles/App.css';
import Home from './components/Home.js';
import About from './components/About.js';
import Header from './components/Header.js'
import WhyUs from './components/WhyUs.js';
import ContactUs from './components/Contact.js';

function App() {
    return (
        <div className='App'>
            <Header />
            <Home />
            <About />
            <WhyUs  />
            <ContactUs />
        </div>
    )
}
export default App;