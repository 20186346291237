import React from "react";
import aboutImage1 from "../assets/img/hotel-booking-engine-reservation-system.jpg";
import aboutImage2 from "../assets/img/door.jpg";
import meetingImg from '../assets/img/meeting.jpg';
import '../styles/About.css';
import '../fonts/fonts.css';

function About() {
    return (
        <section id="about-us" className="about-img" >
            <h1>About Medaresha</h1>
            <div className="container">
                
                <div className="about-content">

                    <p>Medaresha is a mobile app that helps to navigate, facilitates, and arrange bookings, share experiences and
                        hire local tour guides for users in a given location.</p>
                </div>
                <div className="image">
                    <img src={aboutImage1} alt="Car Image" />
                </div>
            </div>

            <div className="container">
                <div className="image">
                    <img src={meetingImg} alt="Car Image" />
                </div>
                <div className="about-content">
                    <p>The company plans to include different services like planning trips and navigating city streets to help find
                        hotels, cafés, restaurants, supermarkets, gas stations, and other public service facilities with advanced
                        booking, and reviewing platforms, in addition to this, it helps a user to find a trained personal tour guide.
                    </p>
                </div>
            </div>

            <div className="container">
                <div className="about-content">
                    <p>Medaresha's online booking system takes advantage of the Internet’s ability to organize and connect the
                        fragmented marketplace of café, restaurants, and hotels, and tour guides into a searchable database that is
                        consumer-focused and easy to use.</p>
                </div>
                <div className="image">
                    <img src={aboutImage2} alt="Car Image" />
                </div>
            </div>
        </section>
    )
}

export default About;
